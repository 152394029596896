export const SITE_NAME = 'Thrivestate'

export const DEFAULT_CACHE_TIME = 60000 * 30 // 30 Minutes

export const DEFAULT_MANDATORY_MESSAGE = 'This field is mandatory'

export enum CALENDAR_MODES {
  SINGLE = 'single',
  RANGE = 'range',
  EXPANDED = 'expanded',
}

export enum AGREEMENT_CODES {
  NEW = 'new',
  REVIEW = 'in_review',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  ONBOARDED = 'onboarded',
}

export enum REFERRALS_CODES {
  NEW = 'new',
  ASSIGNED = 'assigned',
  SALES = 'sales',
  PAYMENTS = 'payments',
  FINALIZED = 'finalized',
}

export enum DEAL_STATUS_CODES {
  PENDING = 'pending',
  PROCESSING = 'processing',
  PAID = 'paid',
  CANCELLED = 'cancelled',
}

export enum DEAL_EARNED_STATUS_CODES {
  PROCESSING = 'processing',
  CANCELED = 'canceled',
  PAYMENT_COMPLETED = 'payment_completed',
}

export enum TOURS_CODES {
  DASHBOARD = 'dashboard',
  EARNINGS = 'earnings',
}

export const defaultQueryFilter = {
  page: 1,
  per_page: 10,
}

export enum PERSIST_QUERY {
  BCLICKID = 'bclickid',
}

export enum INVOICES_ALLOWED_CURRENCIES {
  AED = 'AED',
  USD = 'USD',
  EUR = 'EUR',
}

export const HEADING_VALUE = 'heading'

export enum COMMISSION_STATUSES {
  NOT_ASSIGNED = 'not_assigned',
  SET_COMMISSIONS = 'set_commissions',
  IN_REVIEW = 'in_review',
  APPROVED = 'approved',
}

export enum COMMISSIONS_CODES {
  UNIT_PRICE = 'unit_price',
  AGENT = 'agent',
  BROKERAGE = 'brokerage',
  FIXED = 'fixed',
}

export enum RELEASE_COMPONENTS_CODES {
  FE_CRM = 'fe_crm',
  FE_PARTNERS_PORTAL = 'fe_partners_portal',
  BE_CRM = 'be_crm',
  BE_PROPERTY_CATALOGUE = 'be_property_catalogue',
  BE_WA = 'be_wa',
}

export enum PAYOUT_STATUSES {
  APPROVED = 'approved',
  COMPLETED = 'completed',
  PENDING = 'waiting_approval',
}

export enum MEASURE_LIST_IDS {
  METRIC_ID = 1,
  IMPERIAL_ID = 2,
}

export enum MEASURE_LIST_LABELS {
  METRIC = 'm²',
  IMPERIAL = 'ft²',
}
